import tableState from "../../../templates/table/state";

const customizedTableState = tableState();
customizedTableState.pagination.sortBy = "date";
customizedTableState.pagination.descending = true;

export default {
  ...customizedTableState,
  annotationType: {},
  annotationSubType: {},
  file: {}
};
